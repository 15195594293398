// Index of the hovered placeholder
uniform int hovered;
// Index of the selected placeholder
uniform int selected;

// Size factor depending on placeholder state
uniform float hoveredSizeFactor;
uniform float selectedSizeFactor;

// Attribute providing the hidden state
attribute float hidden;

// Forward instance ID, state and UV coordinates to the fragment shader
flat out int vInstanceId;
flat out int vHovered;
flat out int vSelected;
out vec2 vUv;
out float vHidden;
out float vCamDistance;

#include <clipping_planes_pars_vertex>

void main() {
    // Compute the scale factor depending on the placeholder state
    float scaleFactor = gl_InstanceID == selected ? selectedSizeFactor : (gl_InstanceID == hovered ? hoveredSizeFactor : 1.);

    // Compute this placeholder projection applying the scale factor
    vec3 transformed = vec3( position.x * scaleFactor, position.y * scaleFactor, position.z);
    vec4 mvPosition = vec4( transformed, 1.0 );
	mvPosition = instanceMatrix * mvPosition;
    mvPosition = modelViewMatrix * mvPosition;
    gl_Position = projectionMatrix * mvPosition;

    // Forward data to the fragment shader
    vUv = uv;
    vInstanceId = gl_InstanceID;
    vHovered = hovered;
    vSelected = selected;
    vHidden = hidden;
    vCamDistance = length(mvPosition.xyz);

    #include <clipping_planes_vertex>
}
