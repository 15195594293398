varying vec2 vUv;

#include <clipping_planes_pars_vertex>

void main() {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.f);
    gl_Position = projectionMatrix * mvPosition;
    #include <clipping_planes_vertex>
}
