#include <packing>

varying vec2 vUv;

uniform sampler2D map;

uniform float opacity;

#include <clipping_planes_pars_fragment>

void main() {
    #include <clipping_planes_fragment>
    vec4 texColor = texture2D(map, vUv);

    // Combine the transparency to preserve completely transparent sections of the image
    // while having a smooth transition
    gl_FragColor = texColor;
    gl_FragColor.a = texColor.a * opacity;
}
